import React, { useState } from "react";
import BreadcrumBanner from "../../components/BreadcrumBanner";
import { BrandConfig } from "../../contexts/BrandConfigProvider";

interface FormData {
  name: string;
  contact: string;
  subject: string;
  email: string;
  service: string;
  message: string;
}

const ContactUs = ({ brandInfo }: { brandInfo: BrandConfig }) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    contact: "",
    subject: "",
    email: "",
    service: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      // Construct the WhatsApp message
      const message = encodeURIComponent(
        `Name: ${formData.name}\n` +
          `Contact Number: ${formData.contact}\n` +
          `Email: ${formData.email}\n` +
          `Service Required: ${formData.service}\n` +
          `Subject: ${formData.subject}\n` +
          `Message: ${formData.message}`
      );

      const url = `${brandInfo.whatsappLink}?text=${message}`;
      // Open the URL in a new tab
      window.open(url, "_blank");

      // Optionally, you can reset the form and display success message
      setFormData({
        name: "",
        contact: "",
        email: "",
        service: "",
        subject: "",
        message: "",
      });

      setSubmitSuccess(true);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <BreadcrumBanner />
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg">
        <h2 className="text-2xl font-semibold mb-6">Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="sm:grid grid-cols-1 md:grid-cols-2 gap-2 mb-4 text-left">
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 text-sm font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="contact" className="block text-gray-700 text-sm font-medium mb-2">
                Contact Number
              </label>
              <input
                type="tel"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="service" className="block text-gray-700 text-sm font-medium mb-2">
                Service Required
              </label>
              <select
                id="service"
                name="service"
                value={formData.service}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                required
              >
                <option value="" disabled>
                  Select a service
                </option>
                <option value="Complete thesis">Complete thesis</option>
                <option value="Result">Result</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-span-2 mb-4">
              <label htmlFor="subject" className="block text-gray-700 text-sm font-medium mb-2">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                required
              />
            </div>

            <div className="col-span-2 mb-4">
              <label htmlFor="message" className="block text-gray-700 text-sm font-medium mb-2">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 bg-primary-500 text-white font-semibold rounded-md shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 transition duration-150 ease-in-out"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>

          {submitSuccess !== null && (
            <div
              className={`mt-4 p-4 ${
                submitSuccess ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
              } rounded-md`}
            >
              {submitSuccess ? "Your message has been sent successfully!" : "There was an error sending your message."}
            </div>
          )}
        </form>
      </div>

      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg">
        <iframe
          title="Office Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14008.114841603383!2d77.20498720584882!3d28.628901610664112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd37b741d057%3A0xcdee88e47393c3f1!2sConnaught%20Place%2C%20New%20Delhi%2C%20Delhi%20110001!5e0!3m2!1sen!2sin!4v1723920583547!5m2!1sen!2sin"
          loading="lazy"
          className="w-full min-h-96 m-auto"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
