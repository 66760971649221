import React, { useEffect, useState } from "react";
import { BrandConfig } from "../contexts/BrandConfigProvider";

interface CarouselProps {
  brandInfo: BrandConfig;
}

const Carousel = ({ brandInfo }: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
  
  const images = brandInfo.home.carouselImages;
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 3000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative">
      <img src={images[currentIndex].imageUrl} alt={`Slide ${currentIndex}`} className="w-full h-96 object-cover" />
      <div className="absolute w-full px-8 lg:px-48 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h3 className="text-lg lg:text-2xl font-bold">{images[currentIndex].header}</h3>
        <p className="text-white bold">{images[currentIndex].text}</p>
      </div>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-primary-500 text-white p-2 rounded-r"
      >
        &larr;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-primary-500 text-white p-2 rounded-l"
      >
        &rarr;
      </button>
    </div>
  );
};

export default Carousel;
