import React from "react";
import "./App.css";
import Home from "./pages/home/Home";
import { Route, Routes } from "react-router-dom";
import About from "./pages/about/About";
import Faq from "./pages/faq/Faq";
import Service from "./pages/service/Service";
import Navbar from "./components-ui/Navbar";
import Footer from "./components-ui/Footer";
import ContactUs from "./pages/contactus/ContactUs";
import ServiceDetails from "./pages/service/ServiceDetails";
import LoadingSpinner from "./components-ui/LoadingSpinner";
import { useBrandConfig } from "./contexts/BrandConfigProvider";

function App() {
  const { brandConfig } = useBrandConfig();

  if (!brandConfig) {
    return <LoadingSpinner />;
  }
  return (
    <div className="App text-sm">
      <Navbar brandInfo={brandConfig} />
      <Routes>
        <Route path="/" element={<Home brandInfo={brandConfig} />} />
        <Route path="/about" element={<About brandInfo={brandConfig} />} />
        <Route path="/contact" element={<ContactUs brandInfo={brandConfig} />} />
        <Route path="/faq" element={<Faq brandInfo={brandConfig} />} />
        <Route path="/service">
          <Route index element={<Service brandInfo={brandConfig} />} />
          <Route path=":id" element={<ServiceDetails brandInfo={brandConfig} />} />
        </Route>
        <Route path="*" element={<p>404</p>} />
      </Routes>
      <Footer brandInfo={brandConfig} />
    </div>
  );
}

export default App;
