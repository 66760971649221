import React from "react";
import BreadcrumBanner from "../../components/BreadcrumBanner";
import Accordion from "../../components-ui/Accordian";
import { BrandConfig } from "../../contexts/BrandConfigProvider";

type Props = {
  brandInfo: BrandConfig;
};

const Faq = ({ brandInfo }: Props) => {
  return (
    <div className="">
      <BreadcrumBanner />
      <div className="m-4 text-left max-w-screen-md mx-4 lg:mx-auto flex flex-col gap-4">
        {brandInfo.faq.map((faq, index) => (
          <div key={index} className="">
            <Accordion title={faq.question}>{faq.answer}</Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
