import React from "react";
import { useParams } from "react-router-dom";
import { BrandConfig } from "../../contexts/BrandConfigProvider";

type Props = {
  brandInfo: BrandConfig;
};

const ServiceDetails = ({ brandInfo }: Props) => {
  const param = useParams();
  const matchingService = brandInfo.services.find((s) => s.slug === param.id);
  if (!matchingService) {
    return <div></div>;
  }
  return (
    <div>
      <img src={matchingService.imageUrl} className="w-full max-h-80" alt={matchingService.slug} />
      <div className="m-4">
        <h2 className="text-4xl font-bold my-12">{matchingService.heading}</h2>
        {matchingService.paragraphes.map((text, index) => (
          <React.Fragment key={index}>
            <p className="text-left">{text}</p>
            {!text && <br />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ServiceDetails;
