import React from "react";
import { BrandConfig } from "../contexts/BrandConfigProvider";

interface EnquiryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  brandInfo: BrandConfig;
}

const EnquiryButton = ({ children, brandInfo, ...otherProps }: EnquiryButtonProps) => {
  return (
    <a
      href={`${brandInfo.whatsappLink}?text=${encodeURIComponent(brandInfo.defaultWhatsappMessage)}`}
      target="_blank"
      rel="noopener noreferrer"
      className=""
    >
      <button
        {...otherProps}
        className={`py-2 px-4 rounded bg-red-500 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50`}
      >
        {children}
      </button>
    </a>
  );
};

export default EnquiryButton;
