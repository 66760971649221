import React, { createContext, useContext, useState, useEffect } from "react";

// interface BrandConfig {
//   [key: string]: any; // Define the shape of your JSON data
// }

interface BrandConfigContextProps {
  brandConfig: BrandConfig | null;
}

// Define the type for a single navigation link
interface NavLink {
  label: string;
  href: string;
  subMenuItems?: NavLink[]; // Optional for nested menu items
}

// Define the type for a carousel image
interface CarouselImage {
  imageUrl: string;
  header: string;
  text: string;
}

// Define the type for the text and image sections
interface TextAndImageSection {
  heading: string;
  paragraphs: string[];
  imageUrl: string;
  tabs?: {
    name: string;
    heading: string;
    paragraphs: string[];
    highlights: string[];
  }[];
  highlights?: string[];
}

// Define the type for a card
interface Card {
  heading: string;
  text: string;
}

// Define the type for a service
interface Service {
  imageUrl: string;
  heading: string;
  slug: string;
  paragraphes: string[];
}

// Define the type for a social media link
interface SocialMediaLink {
  imageUrl: string;
  link: string;
  label: string;
}

// Define the type for the entire configuration
export interface BrandConfig {
  navbarContactNumber: string;
  whatsappLink: string;
  defaultWhatsappMessage: string;
  navbarEmail: string;
  address: string;
  facebookLink: string;
  linkdinLink: string;
  youtubeLink: string;
  twitterLink: string;
  instagramLink: string;
  logoUrl: string;
  navLinks: NavLink[];
  home: {
    carouselImages: CarouselImage[];
    carouselBelowImageAndText: {
      welcometext: string;
      imageUrl: string;
      heading: string;
      paragraphs: string[];
    };
    leftRighTextAndImage: TextAndImageSection[];
    cards: Card[];
    testimonies: string[];
  };
  about: {
    leftRighTextAndImage: TextAndImageSection;
    cards: {
      heading: string;
      icon: string;
      text: string;
    }[];
  };
  faq: {
    question: string;
    answer: string;
  }[];
  services: Service[];
  footer: {
    socialMedia: SocialMediaLink[];
  };
}

const BrandConfigContext = createContext<BrandConfigContextProps>({ brandConfig: null });

export const useBrandConfig = () => useContext(BrandConfigContext);

export const BrandConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [brandConfig, setBrandConfig] = useState<BrandConfig | null>(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        // Check local storage first
        const storedConfig = localStorage.getItem("config");
        if (storedConfig) {
          setBrandConfig(JSON.parse(storedConfig));
        } else {
          // Fetch from the JSON file
          const response = await fetch("/brand-info.json");
          const data = await response.json();
          setBrandConfig(data);
          // Store the fetched config in local storage
          localStorage.setItem("brandConfig", JSON.stringify(data));
        }
      } catch (error) {
        console.error("Error fetching or storing the config:", error);
      }
    };

    fetchConfig();
  }, []);

  return <BrandConfigContext.Provider value={{ brandConfig }}>{children}</BrandConfigContext.Provider>;
};
